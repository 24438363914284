<template>
  <div class="main-content">
    <!-- 头 -->
    <div class="top" :style="{backgroundColor:isSkin}">
      <div class="thumb-title">安全设置</div>
    </div>
    <!-- 手机号 -->
    <div class="phone">
      <div class="phone-title" :style="{color:isSkin}">手机号</div>
      <div class="phone-line"></div>
      <div class="phone-phone">{{myPhone}}</div>
      <div class="chang-title" @click.stop="changMethod()">更换手机号</div>
    </div>
    <!-- 密码 -->
    <div class="pwd">
      <div class="pwd-title" :style="{color:isSkin}">密码</div>
      <div class="pwd-line"></div>
      <div class="pwd-phone">*******</div>
      <div class="chang-title" @click.stop="pwdMethod()">修改密码</div>
    </div>
    <!-- 微信 -->
    <div class="wx">
      <div class="wx-title" :style="{color:isSkin}">微信</div>
      <div class="wx-line"></div>
      <div class="wx-phone" v-show="bindWechat">已绑定微信</div>
      <div class="wx-phone" v-show="!bindWechat">还未绑定微信</div>
      <div class="chang-title" @click.stop="bindMethod()" v-show="!bindWechat">绑定</div>
      <div class="chang-title" @click.stop="bind1Method()" v-show="bindWechat">解邦</div>
    </div>
    <!-- 更换手机号 -->
    <el-dialog title="修改手机号" class="dialog" :width="'488px'" :visible.sync="isShowPhone" :close-on-click-modal=false>
      <div class="newPhone">新手机号</div>
      <input class="input-view" v-model="phone" placeholder="请输入新手机号">
      <div class="phoneCode">验证码</div>
      <div class="code-view1">
        <input class="input-view" v-model="code" placeholder="请输入验证码">
        <div class="code1" :style="{color:isSkin}" @click.stop="sendMethod()">{{codeBtn}}</div>
      </div>
       <div class="sure" :style="{backgroundColor:isSkin}" @click="changPhoneMethod()">确认</div>
    </el-dialog>
    <!-- 验证密码 -->
    <el-dialog title="修改密码" class="dialog" :width="'488px'" :visible.sync="isYzShowPwd" :close-on-click-modal=false>
      <div class="oldpwd">手机号</div>
      <input class="input-view" v-model="yzPhone" placeholder="请输入手机号">
      <div class="newpwd">验证码</div>
      <div class="yz-pwd">
        <input class="input-view" v-model="yzCode" placeholder="请输入验证码">
        <div class="send-title" :style="{color:isSkin}" @click.stop="sendsMethod()">{{codeBtn1}}</div>
      </div>
      <div class="sure" :style="{backgroundColor:isSkin}" @click="yzchangPwdMethod()">确认</div>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" class="dialog" :width="'488px'" :visible.sync="isShowPwd" :close-on-click-modal=false>
      <div class="oldpwd">新密码</div>
      <input class="input-view" type="password" v-model="newPwd" placeholder="请输入新密码">
      <div class="newpwd">确认密码</div>
      <input class="input-view" type="password" v-model="surePwd" placeholder="请输入确认密码">
      <div class="sure" :style="{backgroundColor:isSkin}" @click="changPwdMethod()">确认</div>
    </el-dialog>
    <!-- 绑定微信号 -->
    <el-dialog title="绑定微信" class="dialog" :width="'400px'" :visible.sync="isShowWx" :close-on-click-modal=false @close="closeMethod()">
      <div class="wx-img">
        <div id="qrcode" ref="qrcode"></div>
        <!-- <img src="../../../assets/users/wxCode.png" alt=""> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {Message} from 'element-ui'
export default {
  data() {
    return {
      myPhone:'',
      isShowPhone:false,
      isYzShowPwd:false,
      isShowPwd:false,
      isShowWx:false,
      phone:'',
      code:'',
      newPwd:'',
      surePwd:'',
      yzPhone:'',
      yzCode:'',
      codeBtn: '获取验证码',
      isClick: true,
      codeBtn1: '获取验证码',
      isClick1: true,
      bindWechat:false,
      task:''
    }
  },
  computed:{
    isSkin(){
		  return this.$store.state.isSkin
    },
  },
  destroyed(){
    clearInterval(this.task)
  },
  created () {
    let bind = sessionStorage.getItem('bindWechat')
    if (bind == 2) {
      this.bindWechat = true
    }else{
      this.bindWechat = false
    }
    this.getUserInfo()
  },
  methods:{
    /**更换手机号**/ 
    changMethod(){
      this.isShowPhone = true
    },
    /**发送验证码**/ 
    sendMethod(){
      var that = this;
			let count = 60;
			if (that.phone == '') {
        Message({
          showClose: true,
          message: '请输入手机号！',
          type: 'error'
        })
			}else if (that.isClick) {
        that.$api.getSmsCode({
          mobile:that.phone,
        }).then(res=>{
          console.log(res);
          if (res.data.code == 0) {
            Message({
              showClose: true,
              message: '已发送，请注意查收',
              type: 'success'
            })
            that.isClick = false;
            let timer = setInterval(function() {
              count--;
              that.codeBtn = '获取中(' + count + ')';
              if (count == 0) {
                that.isClick = true;
                clearInterval(timer)
                that.codeBtn = '重新获取';
              }
            }, 1000)
					}else{
            that.isClick = true;
          }
        })
      } 
    },
    /**确认更换手机好按钮**/
    changPhoneMethod(){
      var that = this
      if (that.phone == '') {
        Message({
          showClose: true,
          message: '请输入新手机号！',
          type: 'error'
        })
        return
			}
      if (that.code == '') {
        Message({
          showClose: true,
          message: '请输入验证码！',
          type: 'error'
        })
        return
			}
      that.$api.getUpdateMobile({
        mobile:that.phone,
        code:that.code
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '手机号更换成功！',
            type: 'success'
          })
          this.isShowPhone = false
        }
      })
    },
    
    /**修改密码**/ 
    pwdMethod(){
      this.isYzShowPwd = true
    },
    /**发送验证码**/ 
    sendsMethod(){
      var that = this;
			let count = 60;
			if (that.yzPhone == '') {
        Message({
          showClose: true,
          message: '请输入手机号！',
          type: 'error'
        })
			}else if (that.isClick1) {
        that.$api.getSmsCode({
          mobile:that.yzPhone,
        }).then(res=>{
          console.log(res);
          if (res.data.code == 0) {
            Message({
              showClose: true,
              message: '已发送，请注意查收',
              type: 'success'
            })
            that.isClick1 = false;
            let timer = setInterval(function() {
              count--;
              that.codeBtn1 = '获取中(' + count + ')';
              if (count == 0) {
                that.isClick1 = true;
                clearInterval(timer)
                that.codeBtn1 = '重新获取';
              }
            }, 1000)
					}else{
            that.isClick1 = true;
          }
        })
      } 
    },
    /**验证手机号**/
    yzchangPwdMethod(){
      var that = this
      if (that.yzPhone == '') {
        Message({
          showClose: true,
          message: '请输入新手机号！',
          type: 'error'
        })
        return
			}
      if (that.yzCode == '') {
        Message({
          showClose: true,
          message: '请输入验证码！',
          type: 'error'
        })
        return
			}
      that.$api.getCheckingMobileIsLogin({
        mobile:that.yzPhone,
        code:that.yzCode
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '验证成功！',
            type: 'success'
          })
          this.isShowPwd = true
          this.isYzShowPwd = false
        }
      })
    },
    /**修改密码按钮**/
    changPwdMethod(){
      var that = this
      if (that.newPwd == '') {
        Message({
          showClose: true,
          message: '请输入新密码！',
          type: 'error'
        })
        return
			}
      if (that.surePwd == '') {
        Message({
          showClose: true,
          message: '请输入验证码！',
          type: 'error'
        })
        return
			}
      if (that.newPwd != that.surePwd) {
        Message({
          showClose: true,
          message: '确认密码不正确，请重新输入！',
          type: 'error'
        })
        return
			}
      that.$api.getUpdatePassword({
        newPassword:that.newPwd
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '修改密码成功！',
            type: 'success'
          })
          this.isShowPwd = false
        }
      })
    },
    /**绑定微信号**/ 
    bindMethod(){
      this.isShowWx = true
      this.getQrCode()
    },
    bind1Method(){
      var that = this
      that.$api.getRemoveWechat().then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '解邦成功！',
            type: 'success'
          })
          that.getUserInfo()
        }
      })
    },
    /**获取用户信息**/
    getUserInfo(){
      let that = this
      that.$api.getUserInfo({
        file:'',
      }).then(res=>{
        if (res.data.code == 0){
          if (res.data.data.bindWechat == 2) {
            this.bindWechat = true
          }else{
            this.bindWechat = false
          }
          that.myPhone = res.data.data.mobile
        }
      })
    },
    /**获取二维码**/ 
    getQrCode(index){
      var that = this
      that.$api.getQrCode().then(res=>{
        if (res.data.code == 0) {
          that.$nextTick(() => {
            if (that.$refs.qrcode != undefined) {
              that.$refs.qrcode.innerHTML = "";
            }
            let qrcode = new QRCode('qrcode',{
              width: 182, 
              height: 182, 
              text: res.data.data.url
            })
          })
          that.task = setInterval(function () {
            that.checkLogin(res.data.data.ticket)
          },3000)
        }
      })
    },
    /**验证是否关注公众号**/ 
    checkLogin(key){
      var that = this
      that.$api.checkLogin({
        ticket:key
      }).then(res=>{
        console.log(res);
        if (res.data.code == 0) {
          clearInterval(that.task)
          this.isShowWx = false
          that.$router.push({
            path:'/bindMobile',
            query:{
              openID:res.data.data,
              type:'1'
            }
          });
          that.$store.commit('changClick',1)
        }
      })
    },
    /**关闭绑定微信按钮**/ 
    closeMethod(){
      clearInterval(this.task)
    }
  }
}
</script>

<style scoped lang="scss">
.main-content{
  width: 100%;
  .top{
    width: 100%;
    height: 55px;
    border-radius: 4px;
    line-height: 55px;
    .thumb-title{
      font-size: 18px;
      color: #FFFFFF;
      margin-left: 12px;
    }
  }
  .phone{
    margin-top: 40px;
    width: 100%;
    height: 92px;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    justify-items: center;
    align-items: center;
    position: relative;
    .phone-title{
      width: 60px;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      margin-left: 45px;
    }
    .phone-line{
      width: 54px;
      height: 0px;
      border: 1px dashed #E4E4E4;
      transform: rotate(90deg);
    }
    .phone-phone{
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: #333333;
    }
    .chang-title{
      position: absolute;
      right: 20px;
      width: 120px;
      height: 42px;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      line-height: 42px;
      text-align: center;
      color: #666666;
      cursor: pointer;
    }
  }
  .pwd{
    margin-top: 20px;
    width: 100%;
    height: 92px;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    justify-items: center;
    align-items: center;
    position: relative;
    .pwd-title{
      width: 60px;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      margin-left: 45px;
    }
    .pwd-line{
      width: 54px;
      height: 0px;
      border: 1px dashed #E4E4E4;
      transform: rotate(90deg);
    }
    .pwd-phone{
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: #333333;
    }
    .chang-title{
      position: absolute;
      right: 20px;
      width: 120px;
      height: 42px;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      line-height: 42px;
      text-align: center;
      color: #666666;
      cursor: pointer;
    }
  }
  .wx{
    margin-top: 20px;
    width: 100%;
    height: 92px;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    justify-items: center;
    align-items: center;
    position: relative;
    margin-bottom: 200px;
    .wx-title{
      width: 60px;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      margin-left: 45px;
    }
    .wx-line{
      width: 54px;
      height: 0px;
      border: 1px dashed #E4E4E4;
      transform: rotate(90deg);
    }
    .wx-phone{
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: #333333;
    }
    .chang-title{
      position: absolute;
      right: 20px;
      width: 120px;
      height: 42px;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      line-height: 42px;
      text-align: center;
      color: #666666;
      cursor: pointer;
    }
  }
}
.newPhone{
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: -10px;
}
.phoneCode{
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 20px;
}
.code-view1{
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
  .code1{
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
  }
}
.sure{
  width: 76px;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 40px;
  margin-left: 372px;
  cursor: pointer;
}
.oldpwd{
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: -10px;
}
.newpwd{
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 20px;
}
.surepwd{
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 20px;
}
.wx-img{
  width: 200px;
  height: 200px;
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yz-pwd{
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
  .send-title{
    position: absolute;
    right: 20px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    margin-top: 10px;
    cursor: pointer;
  }
}
.input-view{
  width: 448px;
  height: 40px;
  background: #FCFCFC;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 100px;
  font-size: 15px;
  line-height: 40px;  
  color: #666666;
  outline: none;
  margin-top: 10px;
}
</style>